export default {
  //setLoading: (state, value) => (state.loading = value),
  SET_CONFIG (state, config) {
    state.config = config
    sessionStorage.setItem('config', config)
  },

  SET_MENU (state, menu) {
    state.menu = menu
    sessionStorage.setItem('menu', JSON.stringify(menu))
  },

  SET_CATEGORIES (state, categories) {
    state.categories = categories
    sessionStorage.setItem('categories', JSON.stringify(categories))
  },

  SET_IS_LOADING (state, value) {
    state.isLoading = value
  },

  SET_PAGE_INFO (state, pageInfo) {
    state.pageInfo = pageInfo
  },

  SET_FORM_INFO (state, formInfo) {
    state.formInfo = formInfo
  },

  SET_LAST_PRODUCTS (state, lastProducts) {
    state.lastProducts = lastProducts
  },
};
