import { axiosGet } from "../index";

const ENDPOINTS = {
  urlProductsCategory: (category, page) => `/categories/${category}/products?page=${page}`,
  urlProductsAll: (params) => `/categories/all/products${params}`,
  urlProducts: (productsId) => `/products/${productsId}`,
};

export const apiGetProductsCategory = (category, page) => {
  return axiosGet(ENDPOINTS.urlProductsCategory(category, page));
};

export const apiGetProductsAll = (params) => {
  return axiosGet(ENDPOINTS.urlProductsAll(params));
};

export const apiGetProducts = (productsId) => {
  return axiosGet(ENDPOINTS.urlProducts(productsId));
};