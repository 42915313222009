import { 
  apiPostContact, 
} from "../../../utils/repositories/contact";

export default {

  
  async sendForm ({ commit }, payload) {

    commit('SET_LOADING', true)
    const resp = await apiPostContact(payload)
    commit('SET_LOADING', false)
    return resp

  },


};
