import Vue from "vue";
import Vuex from "vuex";

import pageModuleStore, { STORE_PAGE } from "./modules/page";
import cartModuleStore, { STORE_CART } from "./modules/cart";
import contactModuleStore, { STORE_CONTACT } from "./modules/contact";
import productsModuleStore, { STORE_PRODUCTS } from "./modules/products";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    [STORE_PAGE]: pageModuleStore,
    [STORE_CART]: cartModuleStore,
    [STORE_CONTACT]: contactModuleStore,
    [STORE_PRODUCTS]: productsModuleStore
  },
});
