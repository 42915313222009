<template>
  <DropDown type="submenu" class="submenu">
    <template v-slot:trigger="{ openDropdown, isOpen }">
      <button
        @click="openDropdown"
        class="inline-flex items-center gap-2 pl-3 lg:pl-0 hover:text-primary mb-4 lg:mb-0 text-primary lg:text-white"
      >
        Products
        <iconArrowDown
          class="block lg:hidden"
          :class="{ 'rotate-180 duration-500': isOpen }"
        />
      </button>
    </template>
    <template v-slot:content="{ closeDropdown }">
      <div class="submenu__container" @click="toggle">
        <div
          class="submenu__body flex flex-col  items-center"
          @click="closeDropdown"
        >
          <div class="w-auto lg:w-200">
            <a
              @click="
                !category.haveChildrens
                  ? goTo({
                      name: 'products',
                      params: { CategoryId: category.slug },
                    })
                  : null
              "
              :class="[
                category.title
                  ? 'submenu__body__title'
                  : 'submenu__body__category cursor-pointer',
                !category.haveChildrens ? 'cursor-pointer' : '',
              ]"
              v-for="(category, index) in categoriesFormat"
              :key="index"
              v-text="category.name"
            />
          </div>
        </div>
      </div>
    </template>
  </DropDown>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { STORE_PAGE } from "../../store/modules/page";
import DropDown from "../common/DropDown.vue";
import iconArrowDown from "../icons/ArrowDown.vue";

export default {
  mounted() {
    this.fetchCategories();
  },
  data() {
    return {
      open: false,
    };
  },
  components: {
    DropDown,
    iconArrowDown,
  },

  computed: {
    ...mapGetters(STORE_PAGE, ["categories"]),
    categoriesFormat() {
      let categories = [];
      let withOutChildrens = [];
      if (this.categories.length > 0) {
        this.categories.forEach((category) => {
          const categoryData = {
            name: category.name,
            icon: category.icon,
            haveChildrens: category.childrens,
            title: true,
            slug: category.slug,
          };
          if (category.childrens) {
            categories.push(categoryData);
            category.childrens.forEach((subCategory) => {
              categories.push(subCategory);
            });
          } else {
            withOutChildrens.push(categoryData);
          }
        });
      }
      return [...categories, ...withOutChildrens];
    },
  },

  methods: {
    ...mapActions(STORE_PAGE, ["fetchCategories"]),
    toggle() {
      this.open = !this.open;
    },
    goTo(path, name) {
      this.open = false;
      this.$emit("close");
      if (
        (name && this.$route.name != name) ||
        this.$route.name != path.name ||
        JSON.stringify(this.$route.params) != JSON.stringify(path.params)
      )
        this.$router.push(name ? { name: name } : path);
    },
  },
};
</script>
