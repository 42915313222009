const Home = () => import("../views/Home.vue");
const About = () => import("../views/About.vue");
const Category = () => import("../views/Category.vue");
const Products = () => import("../views/Product.vue");
const ProductList = () => import("../views/ProductList.vue");
const DetailProduct = () => import("../views/DetailProduct.vue");
const FormPerzonalize = () => import("../components/FormPerzonalize.vue");
const Contact = () => import("../components/Contact.vue");
const Cart = () => import("../views/Cart.vue");
const Shipping = () => import("../views/Shipping.vue");
const WayToPay = () => import("../views/WayToPay.vue");
const Returns = () => import("../views/Returns.vue");
const TermsAndConditions = () => import("../views/TermsAndConditions.vue");


const routes = [
  {
    path: "/",
    component: Home,
    name: "home",
  },
  {
    path: "/about",
    component: About,
    name: "about",
  },
  {
    path: "/categories/:CategoryId",
    component: Category,
    name: "category",
  },
  {
    path: "/categories/products/:CategoryId",
    component: Products,
    name: "products",
  },
  {
    path: "/products",
    component: ProductList,
    name: "product-list",
  },
  {
    path: "/products/:ProductId",
    component: DetailProduct,
    name: "detail",
  },
  {
    path: "/customize",
    component: FormPerzonalize,
    name: "formperzonalize",
  },
  {
    path: "/contact",
    component: Contact,
    name: "contact",
  },
  {
    path: "/cart",
    component: Cart,
    name: "cart"
  },
  {
    path: "/shipping",
    component: Shipping,
    name: "shipping"
  },
  {
    path: "/returns",
    component: Returns,
    name: "returns"
  },
  {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
    name: "termsAndConditions"
  },
  {
    path: "/way-to-pay",
    component: WayToPay,
    name: "WayToPay"
  }

];

export default routes;
