import { apiPostCart } from "../../../utils/repositories/cart";

export default {

  async fetchCart ({ commit, getters }, payload) {

    /*commit('setLoading', true)
    const reps = await apiPostCart ({
      ...payload, 
      ...{ 
        products: getters.cart, 
        total: getters.total
      }
    })
    if(reps.status==200 && reps.data.send)*/
      commit('setCart', [])
    //commit('setLoading', false)
    //return reps
  },

};
