import {
  apiGetConfig,
  apiGetMenu,
  apiGetCategory,
  apiGetPageInfo,
  apiGetForm,
  apiGetLastProducts,
} from "../../../utils/repositories/page";

export default {
  async fetchPage({ commit }, name) {
    commit("SET_IS_LOADING", true);
    const { data } = await apiGetPageInfo(name);
    commit("SET_PAGE_INFO", data);
    commit("SET_IS_LOADING", false);
    return { data };
  },

  async fetchLastProducts({ commit }) {
    commit("SET_IS_LOADING", true);
    const { data } = await apiGetLastProducts();
    commit("SET_LAST_PRODUCTS", data);
    commit("SET_IS_LOADING", false);
  },

  async fetchConfig({ commit }) {
    const { data } = await apiGetConfig();
    commit("SET_CONFIG", data);
  },

  async fetchMenu({ commit }) {
    const { data } = await apiGetMenu();
    let indx = data.findIndex((elemt) => elemt.href == "/products");

    commit("SET_MENU", {
      first: data.splice(0, indx),
      last: data.splice(1, data.length),
    });
  },

  async fetchCategories({ commit }) {
    const { data } = await apiGetCategory();
    commit("SET_CATEGORIES", data);
    commit("SET_IS_LOADING", false);
  },

  async fetchForms({ commit }, name) {
    const { data } = await apiGetForm(name);
    commit("SET_FORM_INFO", data);
    commit("SET_IS_LOADING", false);
  },
};
