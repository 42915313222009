<template>
  <button
    @click="scrollToTop()"
    :class="['back-to-top-btn', { 'go-top': isTop }]"
  >
    <TopIcon class="h-3 text-white" />
  </button>
</template>
<script>
import TopIcon from "../../components/icons/ArrowTop.vue";

export default {
  name: "BackToTop",
  data() {
    return {
      isTop: false,
    };
  },
  components: {
    TopIcon,
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      scrollPos >= 100 ? (this.isTop = true) : (this.isTop = false);
    });
  },
};
</script>
