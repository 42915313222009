import { axiosGet } from "../index";

const ENDPOINTS = {
  urlConfig: "/config",
  urlMenu: "/menu",
  urlCategory: "/categories",
  urlPageInfo: (name) => `/page-info?name=${name}`,
  urlForm: (name) => `/form/${name}`,
  urlLastProducts: "/last-products",
};

export const apiGetConfig = () => {
  return axiosGet(ENDPOINTS.urlConfig);
};
export const apiGetMenu = () => {
  return axiosGet(ENDPOINTS.urlMenu);
};
export const apiGetCategory = () => {
  return axiosGet(ENDPOINTS.urlCategory);
};
export const apiGetPageInfo = (name) => {
  return axiosGet(ENDPOINTS.urlPageInfo(name));
};
export const apiGetForm = (name) => {
  return axiosGet(ENDPOINTS.urlForm(name));
};
export const apiGetLastProducts = () => {
  return axiosGet(ENDPOINTS.urlLastProducts);
};
