var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DropDown',{staticClass:"submenu",attrs:{"type":"submenu"},scopedSlots:_vm._u([{key:"trigger",fn:function({ openDropdown, isOpen }){return [_c('button',{staticClass:"inline-flex items-center gap-2 pl-3 lg:pl-0 hover:text-primary mb-4 lg:mb-0 text-primary lg:text-white",on:{"click":openDropdown}},[_vm._v(" Products "),_c('iconArrowDown',{staticClass:"block lg:hidden",class:{ 'rotate-180 duration-500': isOpen }})],1)]}},{key:"content",fn:function({ closeDropdown }){return [_c('div',{staticClass:"submenu__container",on:{"click":_vm.toggle}},[_c('div',{staticClass:"submenu__body flex flex-col items-center",on:{"click":closeDropdown}},[_c('div',{staticClass:"w-auto lg:w-200"},_vm._l((_vm.categoriesFormat),function(category,index){return _c('a',{key:index,class:[
              category.title
                ? 'submenu__body__title'
                : 'submenu__body__category cursor-pointer',
              !category.haveChildrens ? 'cursor-pointer' : '',
            ],domProps:{"textContent":_vm._s(category.name)},on:{"click":function($event){!category.haveChildrens
                ? _vm.goTo({
                    name: 'products',
                    params: { CategoryId: category.slug },
                  })
                : null}}})}),0)])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }