<template>
  <footer class="bg-secondary">
    <section class="c-footer">
      <section class="flex flex-col pt-4 gap-y-2 items-center text-white">
        <p
          class="text-white font-medium text-center md:text-start"
          v-text="'Policies'"
        />
        <template v-for="(link, index) in links">
          <router-link
            :key="index"
            :to="{ name: link.name }"
            class="duration-500 hover:text-primary"
            v-text="link.label"
            v-if="!link.hasContent"
          />
        </template>
      </section>
    </section>
    <div class="flex justify-center py-2 items-center gap-4">
      <a href="https://www.instagram.com/milkacakecupke/" target="_blank">
        <IGLineal class="w-10 text-white hover:text-primary" />
      </a>
      <a href="https://www.facebook.com/Mika.cake77" target="_blank">
        <FBLineal class="w-10 text-white hover:text-primary" />
      </a>
      <a href="mailto:milkacake1977@gmail.con">
        <MailLineal class="w-8 text-white hover:text-primary" />
      </a>
    </div>
    <section class="text-center font-light text-white">
      <p>
        ©{{ new Date().getFullYear() }} by Milcake. Proudly created with Amoba
        Software
      </p>
    </section>
  </footer>
</template>

<script>
import FBLineal from "../icons/FBLineal.vue";
import IGLineal from "../icons/IGLineal.vue";
import MailLineal from "../icons/MailLineal.vue";
export default {
  name: "footerBase",
  data() {
    return {
      links: [
        {
          label: "Terms and conditions",
          name: "termsAndConditions",
          pagename: "terms-and-conditions",
          hasContent: false,
        },
      ],
    };
  },
  components: { FBLineal, IGLineal, MailLineal },
};
</script>
