import { 
  apiGetProductsCategory, 
  apiGetProductsAll, 
  apiGetProducts
} from "../../../utils/repositories/products";

export default {

  async getProductsCategory ({ commit }, {category, page}) {
    commit('SET_LOADING', true)
    const resp = await apiGetProductsCategory(category, page)
    commit('SET_LOADING', false)
    return resp

  },

  async getProductsAll ({ commit }, {params}) {
    commit('SET_LOADING', true)
    const resp = await apiGetProductsAll(params)
    commit('SET_LOADING', false)
    return resp

  },

  async getProducts ({ commit }, {productId}) {
    commit('SET_LOADING', true)
    const resp = await apiGetProducts(productId)
    commit('SET_LOADING', false)
    return resp

  },

};
