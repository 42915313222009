<template>
  <main>
    <Loading v-if="isLoading || isLoadingProducts" />

    <div v-show="!isLoading && !isLoadingProducts">
      <Header />
      <router-view></router-view>
      <ScrollTop />
      <Footer />
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from "./components/common/HeaderBase.vue";
import Footer from "./components/common/FooterBase.vue";
import ScrollTop from "./components/common/ScrollTop.vue";
import { STORE_PAGE } from "./store/modules/page";
import Loading from "./components/common/Loading.vue";
import { STORE_PRODUCTS } from "./store/modules/products";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    ScrollTop,
    Loading,
  },
  computed: {
    ...mapGetters(STORE_PRODUCTS, ["isLoadingProducts"]),
    ...mapGetters(STORE_PAGE, ["isLoading"]),
  },
  methods: {
    ...mapActions(STORE_PAGE, ["fetchConfig"]),
  },
  mounted() {
    this.fetchConfig();
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
