<template>
  <section class="relative">
    <slot name="trigger" :openDropdown="openDropdown" :isOpen="isOpen" />
    <section
      v-show="isOpen"
      class="rounded-lg z-10 decorate"
      :class="[styleClass[type]]"
    >
      <slot name="content" :closeDropdown="closeDropdown" />
    </section>
  </section>
</template>

<script>
export default {
  name: "DropDown",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "dropdown",
    },
  },
  data: () => ({
    isOpen: false,
    styleClass: {
      dropdown: ["bg-white absolute shadow-lg w-full top-12"],
      submenu: ["submenu-hover", "lg:bg-white lg:absolute lg:shadow-lg top-12 px-5"],
    },
  }),
  created() {
    window.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) this.isOpen = false;
    });
  },
  methods: {
    openDropdown() {
      if (this.isDisabled) return;
      this.isOpen = !this.isOpen;
    },
    closeDropdown() {
      this.isOpen = false;
    },
  },
};
</script>
