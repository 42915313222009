export default {
  isLoading: (state) => state.loading,
  cart: (state) => state.items,
  total: (state) => { 
    let total = 0

    if (state.items.length > 0) 
      total = Object
              .values(state.items)
              .reduce((acc, { price, cant }) => acc + price * cant, 0)

    return total.toFixed(2)
  }
};
