<template>
  <header>
    <nav class="c-navbar">
      <div class="c-navbar__body">
        <router-link to="/" class="cursor-pointer">
          <img
            width="auto"
            height="auto"
            loading="lazy"
            class="c-navbar__logo"
            src="../../assets/img/logo.svg"
            alt="brand"
          />
        </router-link>
        <div class="block lg:hidden">
          <button @click="toggle" class="flex items-center"><NavIcon /></button>
        </div>

        <div
          class="c-navbar__body__content"
          :class="
            open
              ? ' translate-x-0 bg-secondary text-white'
              : '-translate-x-full  '
          "
        >
          <div
            class="lg:justify-end w-full flex flex-col lg:flex-row text-center lg:text-left"
          >
            <div
              class="flex justify-between lg:hidden z-30 mb-6"
              @click="toggle"
            >
              <img
                loading="lazy"
                class="c-navbar__logo"
                src="../../assets/img/logo.svg"
                alt="brand"
              />
              <button class="flex items-center">
                <NavIcon />
              </button>
            </div>

            <a
              v-for="(option_f, index) in menu.first"
              :key="`${index}-first`"
              @click="goTo(option_f.href)"
              class="c-navbar__link cursor-pointer"
              v-text="option_f.label"
            />

            <OptionProducts v-if="menu" @close="open = false" />

            <a
              v-for="(option_f, index) in menu.last"
              :key="`${index}-last`"
              @click="goTo(option_f.href)"
              class="c-navbar__link cursor-pointer"
              v-text="option_f.label"
            />

            <a
              class="c-navbar__link relative flex justify-center cursor-pointer"
              @click="goTo(null, 'cart')"
            >
              <CartIcon />
              <span
                class="bg-white rounded-full w-5 h-5 absolute text-black flex items-center justify-center text-xs -top-2 lg:top-0 ml-9 lg:ml-4"
                v-text="cant"
                v-if="cant > 0"
              />
            </a>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CartIcon from "../../components/icons/CartIcon.vue";
import NavIcon from "../../components/icons/NavIcon.vue";
import { STORE_CART } from "../../store/modules/cart";
import { STORE_PAGE } from "../../store/modules/page";
import OptionProducts from "../common/OptionProducts.vue";

export default {
  name: "HeaderBase",

  mounted() {
    this.fetchMenu();
  },

  data() {
    return {
      open: false,
    };
  },

  components: {
    NavIcon,
    CartIcon,
    OptionProducts,
  },

  computed: {
    ...mapGetters(STORE_CART, ["cart"]),
    ...mapGetters(STORE_PAGE, ["menu"]),
    cant() {
      let cant = 0;
      this.cart.forEach((product) => {
        cant += product.cant;
      });
      return cant;
    },
  },

  methods: {
    ...mapActions(STORE_PAGE, ["fetchMenu"]),
    toggle() {
      this.open = !this.open;
    },
    goTo(path, name) {
      if (
        (name && this.$route.name != name) ||
        this.$route.name != path.name ||
        JSON.stringify(this.$route.params) != JSON.stringify(path.params)
      ) {
        this.$router.push(name ? { name: name } : path);
        this.open = false;
      }
    },
  },
};
</script>
