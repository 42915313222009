import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_URL;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const axiosGet = (
  path,
  params,
  responseType = "json"
) => {
  return axios.get(`${path}`, {
    params,
    responseType,
  })
  .catch(function (error) {
    return error.response
  });
};

export const axiosPost = (path, data) => {
  return axios.post(`${path}`, {
    ...data,
  })
  .catch(function (error) {
    return error.response
  });
};